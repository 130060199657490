import React, { useState } from 'react'
// import {Box,Image,Text} from "@chakra-ui/react"
import logo from "../images/wes.png"
// import {FaBars} from "react-icons/fa"
import {Link} from "react-scroll"
import "./navbar.css"
import { HiOutlineMenuAlt1} from "react-icons/hi"
// import {GiHamburgerMenu,GiSplitCross} from "react-icons/gi"
import {IoClose} from "react-icons/io5"
import twitterLogo from "../images/twitter.png";
import telegramLogo from "../images/telegram.png";
import discordLogo from "../images/discord.png";
import githubLogo from "../images/github.png";



export const Navbar = () => {
    const [navbar, setNavbar] = useState(false);
    const [showNavbar, setShowNavbar] = useState(false)

    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar)
      }
      const ChangeNavbar = () => {
        if (window.scrollY >= 80) {
          setNavbar(true);
        } else {
          setNavbar(false);
        }
    };
    window.addEventListener("scroll", ChangeNavbar);
  return (
    <>
    <div className={navbar ? "nav activeNav" : "nav"}>
      <div id="navbar">
        <div
          id="logo"
        >
          <img
            className='logoImage'
            src={logo}
            alt="logo"
          />
        </div>
        <div id="nav_menu"  width={{ base: "100%", md: "70%", lg: "40%" }} >
            {/*<p fontSize={{ base: "10px", md: "16px", lg: "18" }} >*/}
            {/*    <Link  to="home"  smooth={true} offset={-100} duration={400}>Main</Link>*/}
            {/*</p>*/}
            {/*<p fontSize={{ base: "10px", md: "16px", lg: "18" }}>*/}
            {/*<Link to="about"  smooth={true} offset={-100} duration={400}>Buisness</Link>*/}
            {/*</p>*/}
            {/*<p fontSize={{ base: "10px", md: "16px", lg: "18" }}>*/}
            {/*<Link to="skills"  smooth={true} offset={-100} duration={400}>Token</Link>*/}
            {/*</p>*/}
            {/*<p fontSize={{ base: "10px", md: "16px", lg: "18" }}>*/}
            {/*<Link to="projects" smooth={true} offset={-100} duration={400}>Diversified POS</Link>*/}
            {/*</p>*/}
            {/*<button className='dummyBtn'></button>*/}
            <button className='docsBtn'>
                <a href='https://drive.google.com/file/d/1sYlhV9oiKVcHtY1uKTrGA6HVeb4P9ABL/view?usp=sharing'>Docs</a>
            </button>
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
        {showNavbar ? (
            <IoClose/>
          ) : (
            <HiOutlineMenuAlt1 fontSize={"35px"}/>
          )}
            {/* <GiHamburgerMenu  fontSize={"25px"}/> */}
        </div>
      </div>
    </div>
    <div className={`nav-elements  ${showNavbar && 'active'}`}>
            <div id='closeBtn' onClick={handleShowNavbar}>
               <IoClose fontSize={"35px"}/>
            </div>
            <div id='menuMob'>
               <div id='menuBox'>
                   <p id='MenuNums'>01</p>
                   <p id='menuText'>Main</p>
               </div>
               <div id='menuBox'><p id='MenuNums'>02</p>
               <p id='menuText'>Platform</p></div>
               <div id='menuBox'><p id='MenuNums'>03</p>
                  <p id='menuText'>Token</p>
               </div>
               <div id='menuBox'><p id='MenuNums'>04</p>
                   <p id='menuText'>Staking</p>
               </div>
            </div>
            <div className='socialIcons'>
                <a href="https://twitter.com/WES_COIN"><img src={twitterLogo} alt="new" /></a>
                <a href="https://t.me/wes_coin"><img src={telegramLogo} alt="new" /></a>
                <a href="/"><img src={discordLogo} alt="new" /></a>

                {/*<img src={githubLogo} alt="new" />*/}
            </div>
        <div id='docsBtn2'>
            <a href="../WES_LitePaper.pdf" target="_blank">
                <button className='docsBtn2'>Docs</button>
            </a>
        </div>
    </div>
   </>
  )
}



import './App.css';

import { AllRoutes } from './Route/AllRoutes';

function App() {
  
  return (
    <div className="App">
     <AllRoutes/>
    </div>
  );
}

export default App;

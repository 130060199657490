import React from 'react'
import "./team.css"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation,FreeMode } from "swiper";


let team = [
    {
        img:"https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/640878fe6ec8b29bedbad48e_2.webp",
        name:"Greg Gopman",
        designation:"CEO",
        desc:`Previously CMO at Ankr, biggest LSD protocol on BSC. Head of growth at Kadena ($1B+ blockchain). Co-Founder Akash (early Cosmos SDK chain, peaking at $450M).`
    },
    {
        img:"https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/640c6ee217d12b901d80348f_dan-2%20(1).webp",
        name:"Dan Peterson",
        designation:"COO",
        desc:`Previously the Revenue Operations Specialist at Blockdaemon (staking firm valued at $3B+). Through his time spent at Blockdaemon - Dan has had exposure
        to most every major institution in the crypto space, along with his experience working with and for giants in the traditional financial space.`
    },
    {
        img:"https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/6426dcf7dbe3436570dd8977_2.webp",
        name:"Alex Cheng",
        designation:"CPO",
        desc:`Previously Sr. Product Manager at Tendermint-Cosmos ($10B+ Layer 1) and Composable Finance (DeFi Ecosystem), bringing deep expertise and connections
        with the Cosmos ecosystem to the team.`
    },
    {
        img:"https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/6408790264ee2e20f9b15897_3.webp",
        name:"Dan Lashin",
        designation:"CTO",
        desc:`Previously CTO at Minter. Developing DeFi on Cosmos since 2019. Notable development portfolio includes custom Cosmos DEX and wallet.`
    },
    
]
let Advisors = [
  {
    img:"https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/6426dcf742b60f41c6cc848e_justin-p-500.webp",
    name:"Justin Reyes",
    desc:"Justin is a DeFi BD & Partnerships Lead at Lido DAO (the leading liquid staking protocol with $10B+ in aum). He has previously held business development leadership positions in the software development and advertising industries. He has been active in the crypto industry since 2012."
  },
  {
    img:"https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/6426dcf7064d7436f215dc56_3-p-500.webp",
    name:"Camyar Matini",
    desc:"Director of Growth Strategy at Blockdaemon (staking firm valued at $3B+), leading commercial and GTM strategy across Blockdaemon's suite of products, including traditional staking, liquid staking, and data APIs."
  },
  {
    img:"https://uploads-ssl.webflow.com/63f49a4bd4ba49c4ae284850/642bf8a56605a92899719eca_dude-2-p-500.webp",
    name:"Marco Robustelli",
    desc:"CMO at Ankr, a leading infrastructure provider for multi-chain RPC connections, dApp development tools, and liquid staking solutions. Marco is an experienced crypto-native marketeer, leading Ankr’s marketing endeavour since 2019. He has also been a mentor at Binance’s Most Valuable Builder program."
  },
]
export const Team = () => {
  return (
    <>
    <div id='teamBox'>
        <h1 id='teamHeading'>Team</h1>
        <div id='scrollContainer' className='scrollContainer'>
        <Swiper
        // onSwiper={setSwiperRef}
        slidesPerView={2.4}
        spaceBetween={-350}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[FreeMode, Pagination,Navigation]}
        className="mySwiper"
      >
           {
            team.map((ele)=>(
                <SwiperSlide id='swiperBox'>
                <div id='teamProfile'>
                    <div id='teamProfileBox'>
                        <div id='profileImgBox'>
                          <img id='profileImage' src={ele.img} alt="imag" sizes="(max-width: 479px) 76vw, 25vw" />
                        </div>
                       <p id='teamName'>{ele.name}</p>
                       <p id='teamDesig'>{ele.designation}</p>
                       <p id='teamDesc'>{ele.desc}</p>
                    </div>
                </div>
                </SwiperSlide>
            ))
           }
           </Swiper>
        </div>
        <div id='teamBoxMob'>
            {
              team.map((ele)=>(
                <div id='teamProfile'>
                    <div id='teamProfileBox'>
                        <div id='profileImgBox'>
                          <img id='profileImage' src={ele.img} alt="imag" sizes="(max-width: 479px) 76vw, 25vw" />
                        </div>
                       <p id='teamName'>{ele.name}</p>
                       <p id='teamDesig'>{ele.designation}</p>
                       <p id='teamDesc'>{ele.desc}</p>
                    </div>
                </div>
              ))
            }
        </div>
        <h1 id='teamHeading2'>Advisors</h1>
        <div id='advisorsBox'>
          {
              Advisors.map((ele)=>(
                <div id='teamProfile2'>
                    <div id='teamProfileBox'>
                        <div id='profileImgBox'>
                          <img id='profileImage2' src={ele.img} alt="imag" sizes="(max-width: 479px) 76vw, 25vw" />
                        </div>
                       <p id='teamName'>{ele.name}</p>
                       <p id='teamDesc'>{ele.desc}</p>
                    </div>
                </div>
              ))
          }
        </div>
    </div>
    </>
  )
}

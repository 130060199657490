import React from 'react'
import "./evincos.css"
import Slide from "react-reveal/Slide";

export const EvonCos = () => {
  return (
    <div className='evmBox'>
       <div>
        <Slide bottom>
        <h1 className='evmHeading1'>Industry Leading Rewards</h1></Slide>
        <Slide bottom><p className='evmHeading2'>Dynamic liquidity providers share platform fees captured in blue-chip assets</p></Slide>
       </div>
    </div>
  )
}

import React from 'react'
import "./feature.css"
import first from "../images/wes.png"
import second from "../images/eth.png"
import third from "../images/arbitrum-logo.png"



const data = [
    {
        image: first,
        text : "Lock $WES liquidity and\n" +
            "Receive revenue from interest.",
    },
    {
        image: second,
        text : "Unlock Your Liquidity’s Greatest Potential."
    },
    {
        image: third,
        text : "Arbitrum’s suite of scaling solutions provides faster speeds at a significantly\n" +
            "lower cost, with the same level of security as Ethereum."
    }
]
export const Features = () => {
  return (
    <div className='featurebox'>
        <div id='innerFeatureBox'>
        {
            data.map((ele)=>(
                <div id='featuresDiv'>
                    <div id='featuresImage'>
                        <img src={ele.image}  />
                    </div>
                    <div id='featuresText'>
                    <p>{ele.text}</p>
                  </div>
                </div>
            ))
        }
        </div>
    </div>
  )
}

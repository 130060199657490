import React from 'react'
import map from "../videos/map.mp4"
import map2 from "../videos/map2.mp4"
import "./lsd.css"
import Slide from "react-reveal/Slide";

export const LSD = () => {
  return (
    <div id='lsdDiv'>
        <video autoPlay loop muted id='lsdVideo'>
           <source src={map} type="video/mp4"/>
        </video>
        <video autoPlay loop muted id='lsdVideo2'>
           <source src={map2} type="video/mp4"/>
        </video>
        <div className='lsdText'>
             <div className='lsdText1'>
             <Slide bottom><h2 className='lsdText1_h2'>PLATFORM FOR WEB3 FINANCE</h2></Slide>
             </div>
             <div className='lsdText_bottom_div'>
               <div id='lsdTextBox1'>
                  <p id='lsdP_tag1'>
                    <Slide bottom><p style={{margin:"0px"}}>Reliable, efficient liquidity</p></Slide><Slide bottom><p style={{margin:"0px"}}>will enable complex products and interoperability to flourish.</p></Slide></p>
               </div>
               <div id='lsdTextBox2'>
                 <p id='lsdP_tag1'>
                  <Slide bottom><p style={{margin:"0px"}}>Boost your DeFi yield!
                  </p></Slide>
                  <Slide bottom><p style={{margin:"0px"}}>Deposit on WES, the best yield on Arbitrum

                  </p></Slide>
                  <Slide bottom><p style={{margin:"0px"}}></p></Slide>
                  <Slide bottom><p style={{margin:"0px"}}></p></Slide></p>
               </div>
             </div>
        </div>
    </div>
  )
}

import React, { useState } from "react";
import "./home.css";
import { Navbar } from "../components/Navbar";
import sample from "../videos/home.mp4";
import home2 from "../videos/home2.mp4";
import twitterLogo from "../images/twitter.png";
import telegramLogo from "../images/telegram.png";
import discordLogo from "../images/discord.png";
import githubLogo from "../images/github.png";
import Slide from "react-reveal/Slide";
import { EvonCos } from "../components/EvonCos";
import { Features } from "../components/Features";
import { LSD } from "../components/LSD";
import { Defi } from "../components/Defi";
import { Team } from "../components/Team";
import { Footer } from "../components/Footer";

export const Home = () => {

  return (
    <>
      <Navbar />
      {/* Background Video */}
      <div className={"bgVideo"}>
        <video autoPlay muted loop id="homeVideo">
          <source src={sample} type="video/mp4" />
        </video>
        <video autoPlay muted loop id="homeVideo2">
          <source src={home2} type="video/mp4" />
        </video>
        <div className="VideoContent">
          <div className="homeText">
            <Slide bottom>
              <p>
                Build the
              </p>
            </Slide>
            <Slide bottom>
              <p> <span id="trillionText">Decentralized</span> world</p>
            </Slide>
            <Slide bottom>
              <p>for everyone</p>
            </Slide>
            <Slide bottom>
              <p></p>
            </Slide>
          </div>
          <div className="SocialMediaLinks">
            <a href="https://twitter.com/WES_COIN"><img src={twitterLogo} alt="new" /></a>
            <img src={discordLogo} alt="new" />
            <a href="https://t.me/wes_coin"><img src={telegramLogo} alt="new" /></a>
            {/*<img src={githubLogo} alt="new" />*/}
          </div>
        </div>
      </div>
      <div className={"introBox"}></div>
      {/* into Box */}
      <div id="newIntro">
        <div>
          <Slide bottom>
            <p className="text1">Simple and powerful community-driven
              finance.</p>
          </Slide>
          <Slide bottom>
            <div className="text2">
              <p>on Ethereum Layer 2</p>
              <p>Powered by Arbitrum</p>
            </div>
          </Slide>
          <Slide bottom>
            <p className="text3">
              WES is web3 platform built on Arbitrum network.            </p>
            <p className="text3">
            Stake your WES token into the platform and earn platform fees.


            </p>
          </Slide>
        </div>
      </div>
      {/* Mobile Content Section */}
      <div className="boxIphone vh">
        <div id="introEva">
          <Slide bottom>
          <h2 className="spacing">
            Introducing <span className="evaSpacing">WES</span>
          </h2></Slide>
        </div>
      </div>
        {/* Evm Cosmos */}
        <EvonCos/>
        {/* features */}
        <Features/>
        {/* LSD */}
        <LSD/>
        {/* Defi */}
        {/*<Defi/>*/}
        {/* Team */}
        {/*<Team/>*/}
        {/* Footer */}
        {/*<Footer/>*/}
    </>
  );
};
